import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import VueMeta from 'vue-meta';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCJwwLoevLLL4gkyQNstLE0TY7wxP2eNoA',
  authDomain: 'fut-2021-5de7f.firebaseapp.com',
  projectId: 'fut-2021-5de7f',
  storageBucket: 'fut-2021-5de7f.appspot.com',
  messagingSenderId: '664858644412',
  appId: '1:664858644412:web:de88a5e1d63cc1441fe5b9',
  measurementId: 'G-L97H4YS9R1'
};

const app = initializeApp(firebaseConfig);
Vue.prototype.$analytics = getAnalytics(app);

Vue.config.productionTip = false;

Vue.use(VueMeta);
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
