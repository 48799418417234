var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-app-bar',{attrs:{"color":_vm.currentRouteName ? _vm.navbarColor : 'primary',"elevation":_vm.currentRouteName ? _vm.navbarElevation : 50,"app":"","dark":"","flat":""}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-spacer',{staticClass:" hidden-lg-and-up"}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"block":"","href":"#/","text":"","x-large":""},on:{"click":function () {
            _vm.$vuetify.goTo(0);
            _vm.$vuetify.goTo(_vm.main.id);
          }}},[_c('v-img',{attrs:{"alt":"sspp-name","contain":"","min-width":"100","src":require("@/assets/other/FUT_white.png"),"width":"200"}})],1)],1),_c('v-spacer'),(_vm.currentRouteName == '/')?_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('v-btn',{staticClass:"hidden-md-and-down text-button",attrs:{"elevation":"0","text":""},on:{"click":function($event){return _vm.$vuetify.goTo(item.id)}}},[_vm._v(_vm._s(item.title))])],1)}):[_c('div',[_c('v-btn',{staticClass:"hidden-md-and-down text-button",attrs:{"href":_vm.mainpage.url,"elevation":"0","text":""}},[_vm._v(_vm._s(_vm.mainpage.title))])],1)],_vm._l((_vm.pages),function(page){return _c('div',{key:page.title},[_c('v-btn',{staticClass:"hidden-md-and-down text-button",attrs:{"href":page.url,"elevation":"0","text":""}},[_vm._v(" "+_vm._s(page.title)+" ")])],1)})],2),_c('v-navigation-drawer',{staticClass:"primary",attrs:{"app":"","dark":"","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){{
            _vm.$vuetify.goTo(0);
            _vm.$vuetify.goTo(_vm.main.id);
          }}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"block":"","href":"#/","text":"","x-large":""},on:{"click":function () {
                _vm.$vuetify.goTo(0);
                _vm.$vuetify.goTo(_vm.main.id);
              }}},[_c('v-img',{attrs:{"alt":"Fut-name","contain":"","min-width":"100","src":require("@/assets/other/FUT_white.png"),"width":"170"}})],1)],1)]),(_vm.currentRouteName == '/')?_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,on:{"click":function($event){return _vm.$vuetify.goTo(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}):[_c('v-list-item',{attrs:{"href":_vm.mainpage.url}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.mainpage.title)+" ")])],1)],1),_c('div',[_c('v-btn',{staticClass:"hidden-md-and-down text-button",attrs:{"elevation":"0","text":""}},[_vm._v(_vm._s())])],1)],_vm._l((_vm.pages),function(page){return _c('v-list-item',{key:page.url,attrs:{"href":page.url}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(page.title)+" ")])],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }